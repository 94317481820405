<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
          <iq-card>
            <template v-slot:body>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10">
                       <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="feeName.org_id"
                            :options="organizationList"
                            id="org_id"
                            @change="changeOrganizationId(feeName.org_id)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Service" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="feeName.service_id"
                            :options="serviceList"
                            id="service_id"
                            @change="changeServiceId(feeName.service_id)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="amount" vid="amount" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="amount"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.amount')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="amount"
                              v-model="feeName.amount"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider :vid="`challan_no`" rules="">
                          <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="tax"
                          slot-scope="{ valid, errors }"                                  >
                          <template v-slot:label>
                            {{$t('component_settings.challan_no')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-select
                              plain
                              v-model="feeName.renew_challan_id"
                              :options="challanList"
                              :id="`challan_no`"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Vat" vid="vat" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="vat"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('step_assign.vat')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="vat"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              v-model="feeName.vat"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Expire Type" vid="expire_type" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_type"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.date_expire_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-radio-group
                            v-model="feeName.expire_type"
                            :options="expireTypeList"
                            class="mb-3"
                            value-field="value"
                            text-field="text"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-show="feeName.expire_type === 1">
                      <ValidationProvider name="Expire Date" vid="expire_date" :rules="`${feeName.expire_type === 1 ? 'required' : ''}`">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_date"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.fixed_date')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                              id="expire_date"
                              v-model="feeName.expire_date"
                              :placeholder="$t('globalTrans.select_date')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-show="feeName.expire_type === 2">
                      <ValidationProvider name="Enter days" vid="expire_days" :rules="`${feeName.expire_type === 2 ? 'required' : ''}`">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="expire_days"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.enter_days')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="expire_days"
                              v-model="feeName.expire_days"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="TAX" vid="tax" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="tax"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('step_assign.tax')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="tax"
                              v-model="feeName.tax"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Type" vid="type" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="type"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.fee_type')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="feeName.type"
                            :options="typeList"
                            id="type"
                            @change="changeFeeType(feeName.type)"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Type" vid="payment_from" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="payment_from"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.payment_from')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="feeName.payment_from"
                            :options="paymentFromList"
                            id="payment_from"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-if="feeName.type === 3 && feeName.payment_from === 1">
                      <ValidationProvider name="Amount Type" vid="amount_type" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="amount_type"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('component_settings.amount_type')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-radio-group
                          v-model="feeName.amount_type"
                          :options="amountTypeList"
                          class="mb-3"
                          value-field="value"
                          text-field="text"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-radio-group>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-if="feeName.type === 3 && feeName.payment_from === 1 && feeName.amount_type === 1">
                      <ValidationProvider name="Fixed amount" vid="fixed_amount" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fixed_amount"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{$t('component_settings.fixed_Amount')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          id="fixed_amount"
                          v-model="feeName.fixed_amount"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-if="feeName.type === 3 && feeName.payment_from === 1 && feeName.amount_type === 2">
                      <ValidationProvider name="Percentage" vid="percentage_amount" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="percentage_amount"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.percentage')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="percentage_amount"
                              v-model="feeName.percentage_amount"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10" v-if="feeName.type === 3 && feeName.payment_from >= 2">
                      <ValidationProvider name="examine_payment_from_type" vid="examine_payment_from_type" rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="examine_payment_from_type"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('globalTrans.select_field') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="examine_payment_from_type"
                            :options="inputList"
                            id="examine_payment_from_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value=null>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider :vid="`challan_no`">
                          <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="tax"
                          slot-scope="{ valid, errors }"                                  >
                          <template v-slot:label>
                            {{$t('component_settings.challan_no')}}
                          </template>
                            <b-form-select
                              plain
                              v-model="feeName.expire_challan_id"
                              :options="challanList"
                              :id="`challan_no`"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                              <template v-slot:first>
                                <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="10">
                      <ValidationProvider name="Ekpay Vat Challan_no" vid="ekpay_vat_challan_no">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="ekpay_vat_challan_no"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('component_settings.ekpay_vat_challan_no')}}
                          </template>
                          <b-form-input
                              id="ekpay_vat_challan_no"
                              v-model="feeName.ekpay_vat_challan_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { dynamicFormEditData, renewLostStore, renewLostUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'
import { snakeToWords } from '@/Utils/fliter'
import flatpickr from 'flatpickr'
export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      feeName: {
        org_id: 0,
        service_id: 0,
        type: 0,
        amount: '',
        vat: '',
        tax: '',
        expire_type: 1,
        amount_type: 1,
        expire_date: '',
        expire_days: '',
        percentage: '',
        fixed_amount: '',
        payment_from: 0,
        type_payment: null,
        type_checkbox: null,
        renew_challan_id: 0,
        expire_challan_id: 0
      },
      typeList: [
        { value: 3, text: 'Renew' },
        { value: 4, text: 'lost' }
      ],
      expireTypeList: [
        { value: 1, text: this.$t('component_settings.fixed_date') },
        { value: 2, text: this.$t('component_settings.enter_days') }
      ],
      amountTypeList: [
        { value: 1, text: this.$t('component_settings.fixed_Amount') },
        { value: 2, text: this.$t('component_settings.percentage') }
      ],
      paymentFromList: [
        { value: 1, text: this.$t('component_settings.normal') },
        { value: 2, text: this.$t('component_settings.type_payment') },
        { value: 3, text: this.$t('component_settings.type_checkbox') }
      ],
      loading: false,
      serviceList: [],
      serviceStepForm: null,
      inputList: [],
      examine_payment_from_type: null,
      challanList: []
    }
  },
  created () {
    this.feeName.org_id = this.userCheck()
    if (this.id) {
      const tmp = this.getFeeNameData()
      this.serviceList = this.getServiceList(tmp.org_id)
      this.serviceStepForm = this.getServiceStepForm(tmp.service_id)
      if (tmp.payment_from === 2) { this.examine_payment_from_type = tmp.type_payment }
      if (tmp.payment_from === 3) { this.examine_payment_from_type = tmp.type_checkbox }
      this.feeName = tmp
    }
  },
  watch: {
    'feeName.payment_from': function (newVal, oldVal) {
      if (newVal >= 2 && oldVal <= 1) { this.getInputList(this.getDynamicFormIdFromServiceStepForm()) }
    },
    'feeName.service_id': function (newVal) {
      this.challanList = this.getChallanList(newVal)
    }
  },
  mounted () {
    core.index()
    flatpickr('#expire_date', {})
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  methods: {
    /**
     * @change
     */
    getChallanList (serviceId) {
      return this.$store.state.licenseRegistration.commonObj.challanList.filter(item => item.status === 1 && item.service_id === serviceId)
    },
    changeOrganizationId (orgId) {
      this.feeName.service_id = 0
      this.serviceList = this.getServiceList(orgId)
    },
    changeServiceId (serviceId) {
      this.examine_payment_from_type = null
      this.inputList = []
      this.serviceStepForm = this.getServiceStepForm(serviceId)
      this.getInputList(this.getDynamicFormIdFromServiceStepForm())
    },
    changeFeeType (feeType) {
      this.getInputList(this.getDynamicFormIdFromServiceStepForm())
    },

    incorporateDateExpireType () {
      if (this.feeName.expire_type === 1) {
        this.feeName.expire_days = null
      } else if (this.feeName.expire_type === 2) {
        this.feeName.expire_date = null
      }
    },
    incorporateExaminePaymentFromType () {
      if (this.feeName.payment_from === 2) {
        // Type Payment
        this.feeName.type_payment = this.examine_payment_from_type
        this.feeName.type_checkbox = null
      } else if (this.feeName.payment_from === 3) {
        // Type Checkbox
        this.feeName.type_checkbox = this.examine_payment_from_type
        this.feeName.type_payment = null
      } else {
        this.feeName.type_checkbox = this.feeName.type_payment = null
      }
    },
    getServiceStepForm (serviceId) {
      const tmpServiceStepForm = this.$store.state.licenseRegistration.commonObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      return tmpServiceStepForm !== undefined ? tmpServiceStepForm : null
    },
    getDynamicFormIdFromServiceStepForm () {
      return this.serviceStepForm !== null ? this.serviceStepForm.form_id : 0
    },
    getServiceList (orgID) {
      const serviceList = this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgID)
      return serviceList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getFeeNameData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getInputList (dynamicFormId) {
      if (!dynamicFormId) { return false }
      if (this.feeName.service_id === 0) { return false }
      if (this.feeName.type === 0 || this.feeName.type === 4) { return false }
      if (this.feeName.payment_from === 0 || this.feeName.payment_from === 1) { return false }

      this.loading = true
      RestApi.getData(licenseRegistrationServiceBaseUrl, dynamicFormEditData + '/' + dynamicFormId).then(response => {
        if (response.success && response.data) {
          const finalList = []
          // 1) Tabs
          response.data.tabs.forEach(item => {
            const tmpList = {
                label: snakeToWords(item.tab_name),
                options: []
              }
            // 2) Layouts
            item.layouts.forEach(i => {
              const input = JSON.parse(i.data)
              if (input.field_type === 'text' || input.field_type === 'number' || input.field_type === 'options' || input.field_type === 'paymentCheckbox' || input.field_type === 'payment' || input.field_type === 'dropdown' || input.field_type === 'text_area' || input.field_type === 'text' || input.field_type === 'email' || input.field_type === 'date') {
                  tmpList.options.push({
                    text: input.label, value: input.field_name
                  })
                }
            })
            finalList.push(tmpList)
          })
          this.inputList = finalList
        } else {
          /** Data Not Found */
          this.inputList = []
        }
        this.loading = false
      })
    },
    async register () {
      this.incorporateDateExpireType()
      this.incorporateExaminePaymentFromType()

      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.loading = true
      if (this.feeName.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${renewLostUpdate}/${this.id}`, this.feeName)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, renewLostStore, this.feeName)
      }
      this.loading = false
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
